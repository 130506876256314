<template>
    <r-e-dialog title="编辑单价" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel" width="600px">
        <el-form ref="formPublish" label-width="60px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="小区" prop="name">
                <el-input disabled v-model="formPublish.name"/>
            </el-form-item>
            <el-form-item label="单价" prop="monthPriceAmount">
                <el-input v-model="formPublish.monthPriceAmount">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
<!--            <el-form-item label="半年租金折扣" prop="sixMonthDiscount">
                <el-input type="number" v-model="formPublish.sixMonthDiscount">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item label="年租金折扣" prop="yearDiscount">
                <el-input type="number" v-model="formPublish.yearDiscount">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>-->
        </el-form>
    </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {communityDefaultAmount} from "@/api/community-management";
import {MessageSuccess} from "@custom/message";

export default {
    name: "layer-add-edit-unit-price",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                name: null,
                uuid: null,
                monthPriceAmount: null,
                sixMonthDiscount: null,
                yearDiscount: null,
            },
            rules: {
                monthPriceAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
                sixMonthDiscount: [{required: true, message: "请输入半年折扣", trigger: "blur"},],
                yearDiscount: [{required: true, message: "请输入年折扣", trigger: "blur"},],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    methods: {
        //打开编辑单价弹窗方法
        openDialog(data) {
            //拆分传递过来的数据
            let {name, uuid, sixMonthDiscount, yearDiscount, monthPriceAmount} = data;
            //金额转换
            monthPriceAmount = ParseFloatFormat(monthPriceAmount);
            //赋值展示
            this.formPublish = {name, uuid, sixMonthDiscount, yearDiscount, monthPriceAmount}
            //打开弹窗
            this.dialogVisible = true;
        },
        // 弹框确认方法
        handleSubmitPublish() {
            let that = this;
            //校验表单
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //拆分数据
                    let {uuid, monthPriceAmount, sixMonthDiscount, yearDiscount} = that.formPublish;
                    //金额转换
                    monthPriceAmount = ParseIntFormat(monthPriceAmount);
                    //组装数据
                    let params = {uuid, monthPriceAmount, sixMonthDiscount, yearDiscount}
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    //调用接口，编辑单价
                    communityDefaultAmount(params).then(res => {
                        MessageSuccess("编辑成功");
                        that.handleCancel();
                    }).finally(() => loading.close());
                } else {
                    return false;
                }
            });
        },
        // 弹框关闭方法
        handleCancel() {
            //重置表单
            this.$refs["formPublish"].resetFields();
            // 取消、关闭弹窗
            this.dialogVisible = false;
            //调用上层方法查询
            this.$emit("handleSearch");
        },

    }
}
</script>

<style scoped>

</style>